import Nav from './components/nav';
import Fot from './components/footer';
import enjo from './assets/imaginmri.jpg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';

export default function EnjoMri() {
    const { t } = useTranslation();
    const [lightModeScreen, setLightModeScreen] = useState(true);

    function MyComponent() {
        let navigate = useNavigate();

        const goToAnotherPage = () => {
            navigate('/services');
        };

        useEffect(() => {
            lightModeScreenManager();
            const intervalId = setInterval(lightModeScreenManager, 100);
            return () => {
                clearInterval(intervalId);
            };
        }, []);

        function lightModeScreenManager() {
            let savedValueForMode = localStorage.getItem('Mode');
            if (savedValueForMode == null) {
                setLightModeScreen(true);
                localStorage.setItem('Mode', true);
            } else {
                setLightModeScreen(savedValueForMode === 'true');
            }
        }

        return (
            <div className='services-background-fixer' style={{ backgroundColor: lightModeScreen ? '#ffffff' : '#1E1E1E' }}>
                <Nav />
                <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <div className="services-header-ult">
                        <h1 style={{ color: lightModeScreen ? '#535353' : 'white' }}>{t("enjoyablemrimaintext1")}</h1>
                        <h2 style={{ color: lightModeScreen ? '#535353' : 'white' }}>{t("enjoyablemrimaintext2")}</h2>
                        <img src={enjo} />
                    </div>
                    <div className="services-parag-ult">
                        <p style={{ color: lightModeScreen ? '#555' : '#dedede' }}>{t("enjoyablemrimaindesc")}
                        </p>
                    </div>
                </div>
                <div className="wrapper">
                    <section className="info-section">
                        <h2 className="info-header" style={{ color: lightModeScreen ? '#535353' : 'white' }}>{t("enjoyablemrimainquestion")}</h2>
                        <p className="info-content" style={{ color: lightModeScreen ? '#555' : '#dedede' }}>
                            <ul>
                                <li style={{ color: lightModeScreen ? '#555' : '#dedede' }}>{t("enjoyablemritext1")}</li>
                                <li style={{ color: lightModeScreen ? '#555' : '#dedede' }}>{t("enjoyablemritext2")}</li>
                                <li style={{ color: lightModeScreen ? '#555' : '#dedede' }}>{t("enjoyablemritext3")}</li>
                            </ul>

                            {t("enjoyablemritext4")}<h3>{t("enjoyablemritext5")}</h3></p>

                    </section>

                    <div className="buttons-area-for-infos" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                        <button className="buttons-area-for-infos-btn" onClick={goToAnotherPage}>{t("enjoyablemribackbutton")}</button>
                        <button className="buttons-area-for-infos-btn">{t("enjoyablemriappointmentbutton")}</button>
                    </div>
                </div >
                <Fot />
            </div>
        );
    }

    return <MyComponent />;
}
