import React, { useState, useEffect } from 'react';
import Nav from './components/nav';
import Fot from './components/footer';
import appoitimage from '../src/appoitnment/appointmentphoto.svg';
import { useTranslation } from 'react-i18next';

export default function Appointments() {
    const {t} = useTranslation();
    const [service, setService] = useState('ultrasound');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorText, setErrorText] = useState('Error happened!');
    const [showError, setShowError] = useState(false);
    const [errorTextColor, setErrorTextColor] = useState('red');
    const [lightModeScreen, setLightModeScreen] = useState(true);

    const [lastSubmissionTime, setLastSubmissionTime] = useState(() => {
        const storedTime = localStorage.getItem('lastSubmissionTime');
        return storedTime ? parseInt(storedTime, 10) : 0;
    });

    useEffect(() => {
        localStorage.setItem('lastSubmissionTime', lastSubmissionTime);
    }, [lastSubmissionTime]);

    const handleServiceChange = (event) => {
        setService(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(name == "" || phone == "" || email == ""){
            errorTextHandler("Please fill name, phone and email.", "red");
        }else{
    
        const currentTime = Date.now();
        const timeSinceLastSubmission = currentTime - lastSubmissionTime;
    
        if (timeSinceLastSubmission < 60000) {
            errorTextHandler("Please wait 1 minute before submitting another appointment.", "red");
            return;
        }
    
        setIsSubmitting(true);
        if(checkChars(service, name, phone, email, message) == false){
        try {
            const response = await fetch("https://ridc.ae/addrequestappointments", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    service: service,
                    name: name,
                    phone: phone,
                    email: email,
                    message: message
                })
            });
    
            if (!response.ok) {
                const data = await response.json();
                errorTextHandler(data.errormessage, data.errorcolor);
                if (response.status === 400 && data.errormessage.includes("You are blocked")) {
                    setLastSubmissionTime(currentTime);
                    errorTextHandler("You are blocked. Please wait before submitting another appointment.", "red");
                }
                clearAppointmentInputs()
                return;
            }
    
            setLastSubmissionTime(currentTime);
            errorTextHandler("Appointment is submitted successfully, please wait for a phone call.", "#1dcc71");
            clearAppointmentInputs();
            
        } catch (error) {
            console.error("Fetch error:", error);
            errorTextHandler("An error occurred while submitting the appointment!", "red");
            clearAppointmentInputs()
        } finally {
            setIsSubmitting(false);
            clearAppointmentInputs()
        }
    }else{
        errorTextHandler("Please remove any special characters you are using!", "red");
        clearAppointmentInputs()
    }
    }
    };

    function errorTextHandler(errormsg, errorcolor) {
        setErrorText(errormsg);
        setErrorTextColor(errorcolor);
        setShowError(true);
    }

    function containsSpecialCharacters(str) {
        const pattern = /[<>&'"]/;
        return pattern.test(str);
    }
    
    function checkChars(service, name, phone, email, message) {
        const strings = [service, name, phone, email, message];
        for (const str of strings) {
            if (containsSpecialCharacters(str)) {
                return true;
            }
        }
    
        return false;
    }

    function clearAppointmentInputs(){
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");
    }

    useEffect(() => {
        lightModeScreenManager();
        const intervalId = setInterval(lightModeScreenManager, 100);
        return () => {
          clearInterval(intervalId);
        };
    }, []);
  
      function lightModeScreenManager() {
        let savedValueForMode = localStorage.getItem('Mode');
        if (savedValueForMode == null) {
          setLightModeScreen(true);
          localStorage.setItem('Mode', true);
        } else {
          setLightModeScreen(savedValueForMode === 'true');
        }
      }
    

    return (
        <>
            <Nav />
            <div style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                        <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("appointmentmaintext1")}</h1>
                    </div>
                </div>
                <div className="appointment-section" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <div className="form-container" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                        <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("appointmentmaintext2")}</h1>
                        <p style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("appointmentmaintext2desc")}</p>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="service" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("appointmentmaintextselectservice")}</label>
                            <select
                                id="service"
                                name="service"
                                value={service}
                                onChange={handleServiceChange}
                            >
                                <option value="ultrasound">{t("appointmentmaintextservices1")}</option>
                                <option value="ctscan">{t("appointmentmaintextservices2")}</option>
                                <option value="mri">{t("appointmentmaintextservices3")}</option>
                                <option value="digitalxray">{t("appointmentmaintextservices4")}</option>
                                <option value="digitalmomogram">{t("appointmentmaintextservices5")}</option>
                                <option value="dentalimagining">{t("appointmentmaintextservices6")}</option>
                            </select>

                            <label htmlFor="name" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("appointmentform1")}</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={handleNameChange}
                            />

                            <label htmlFor="phone" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("appointmentform2")}</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                maxLength="15"
                                pattern="[0-9]*"
                                title="Enter your number."
                                value={phone}
                                onChange={handlePhoneChange}
                            />

                            <label htmlFor="email" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("appointmentform3")}</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                            />

                            <label htmlFor="message" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("appointmentform4")}</label>
                            <textarea
                                id="message"
                                name="message"
                                value={message}
                                onChange={handleMessageChange}
                            />
                            {showError && (
                                <div className='errortextbox'>
                                    <h3 className='errortext' style={{ display: 'block', color: errorTextColor}}>{errorText}</h3>
                                </div>
                            )}
                            <h4 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("appointmenttextunderform")}</h4>
                            <input
                                type="submit"
                                value="MAKE AN APPOINTMENT"
                                className="submit-button"
                                disabled={isSubmitting}
                            />
                        </form>
                    </div>
                    <div className="doctor-image">
                        <img src={appoitimage} alt="Doctor" />
                    </div>
                </div>
            </div>
            <Fot />
        </>
    );
}
