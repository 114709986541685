import React, { useState, useEffect } from 'react';
import Nav from '../components/nav';
import Fot from '../components/footer';
import { useNavigate } from 'react-router-dom';
import acc from  './assets/accounts.jpg';
import blockip from './assets/blockip.jpg';
import logs from './assets/logs.jpg';
import manageapp from './assets/manageapp.jpg';
export default function Admin() {

    function deleteTokenCookie() {
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = "/login";
    }
    function goToClientPage() {
        window.location.href = "/clients";
    }


    return (
        <div className='admin-whole-page'>
             <Nav />
             
            <div className="card-container-admins">
                <a href="/accounts">
        <div className="card-admins">
            <img src={acc} alt="Photo 1"/>
            <h3>Accounts</h3>
        </div>
        </a>
        <a href="/blockipsmanager">
        <div className="card-admins">
            <img src={blockip} alt="Photo 2"/>
            <h3>Blockips Manager</h3>
        </div>
        </a>
        <a href="/log">
        <div className="card-admins">
            <img src={logs} alt="Photo 3"/>
            <h3>Logs</h3>
        </div>
        </a>
        <a href="/manageappointments">
        <div className="card-admins">
            <img src={manageapp} alt="Photo 4"/>
            <h3>Manage Appointments</h3>
        </div>
        </a>
    </div>
                <div className='login-area'>
                <button className='logout-button' onClick={()=> deleteTokenCookie()}>LogOut</button>
                <button className='clients-button' onClick={()=> goToClientPage()}>Cleints</button>
             </div>
        </div>
    );
}
