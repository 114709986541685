import Nav from './components/nav';
import Fot from './components/footer';
import mriimag from './assets/news/MRI2.webp';
import mripros from './assets/news/MRI_Prostate-760x581.webp';
import momophot from './assets/news/Mamo.webp';
import newphot from './assets/news/New-time-760x581.webp';
import brestpho from './assets/news/breast-cancer1-760x581.webp';
import enjo from './assets/ENJOYABLE-MRI-EXPERIENCE.webp';
import mriimg from './assets/mRI.webp';
import ct from './assets/CT.webp';
import degm from './assets/Digital-Mammography.webp';
import degx from './assets/Digital-Xray.webp';
import degp from './assets/dental-imaging.webp';
import ult from './assets/ULTRASOUND-RIDC.webp';
import img1 from './assets/dental-circle-ridc-01-200x200.webp';
import img2 from './assets/services-body-abdominal-200x200.webp';
import img3 from './assets/services-body-brain-spine-200x200.webp';
import img4 from './assets/services-body-chest-200x200.webp';
import img5 from './assets/services-body-mammography-200x200.webp';
import img6 from './assets/services-body-musculoskeletal-200x200.webp';
import img7 from './assets/services-body-prostate-1-200x200.webp';
import img8 from './assets/services-body-prostate-200x200.webp';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function Gellary() {
    const [lightModeScreen, setLightModeScreen] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        lightModeScreenManager();
        const intervalId = setInterval(lightModeScreenManager, 100);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    function lightModeScreenManager() {
        let savedValueForMode = localStorage.getItem('Mode');
        if (savedValueForMode == null) {
            setLightModeScreen(true);
            localStorage.setItem('Mode', true);
        } else {
            setLightModeScreen(savedValueForMode === 'true');
        }
    }

    return (
        <>
            <Nav />
            <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <h1 style={{ color: lightModeScreen ? '#333333' : 'white' }}>{t("gellarymain")}</h1>
                </div>
            </div>
            <div className='news-container' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={mriimag} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={momophot} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={mripros} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={newphot} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={brestpho} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={enjo} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={mriimg} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={ct} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={degm} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={degx} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={degp} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={ult} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img1} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img2} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img3} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img4} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img5} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img6} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img7} alt="MRI" />
                </div>

                <div className="news-card fade-in" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <img src={img8} alt="MRI" />
                </div>
            </div>

            <Fot />
        </>
    );
}


