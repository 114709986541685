import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Admin from './admin/main';
import Clients from './clients';
import Login from './login';
import Home from './home';
import Services from './services';
import Ultrasound from './ultrasound';
import Mri from './mri';
import Enjoymri from './enjomriexp';
import Ctscan from './ctscan';
import Contactus from './contactus';
import DigitalMammogram from './digitalmomo';
import DigitalXray from './digitalxray';
import DintalImagining from './dentalimagining';
import Brainhead from './brainhead';
import Chestimag from './chestimag';
import Breastimag from './breastimag';
import Abdomenimag from './abdomenimag';
import Appointments from './Appointments';
import ManageAppointments from './admin/manageappointments';
import Dentalimag from './dentalimag';
import Prostateimag from './prostateimag';
import Maskuloskelettalimag from './maskulul';
import Vascularimag from './vaskulul';
import Accounts from './admin/accounts';
import OurNews from './ournews';
import Gellary from './gellary';
import Aboutus from './aboutus';
import Blocksipmanager from './admin/blockipsmanager';
import Ourteam from './ourteam';
import AdminRouteGuard from './AdminRouteGuard';
import UserRouteGuard from './UserRouteGuard';
import DoctorRouteGuard from './DoctorRouteGuard';
import ClientServices from './client/clientservices';
import ClientServicesList from './doctors/clientserviceslist';
import Doctors from './doctors';
import Log from './admin/log';
import Logo from './assets/logo2.webp';

export default function App() {

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<HomeWithHelmet />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/services" element={<ServicesWithHelmet />} />
                    <Route path="/ourteam" element={<OurTeamWithHelmet />} />
                    <Route path="/contactus" element={<Contactus />} />
                    <Route path="/appointments" element={<AppointmentsWithHelmet />} />
                    <Route path="/ourNews" element={<OurNewsWithHelmet />} />
                    <Route path="/gallery" element={<GellaryWithHelmet />} />
                    <Route path="/aboutus" element={<AboutusWithHelmet />} />

                    <Route path="/ultrasound" element={<UltrasoundWithHelmet />} />
                    <Route path="/mri" element={<MriWithHelmet />} />
                    <Route path="/enjoymriexperience" element={<EnjoymriWithHelmet />} />
                    <Route path="/ctscan" element={<CtscanWithHelmet />} />
                    <Route path="/digitalmammogram" element={<DigitalMammogramWithHelmet />} />
                    <Route path="/digitalxray" element={<DigitalXrayWithHelmet />} />
                    <Route path="/dentalimagining" element={<DintalImaginingWithHelmet />} />
                    <Route path="/brainhead" element={<BrainheadWithHelmet />} />
                    <Route path="/chestimaging" element={<ChestimagWithHelmet />} />
                    <Route path="/breastimaging" element={<BreastimagWithHelmet />} />
                    <Route path="/abdomenimaging" element={<AbdomenimagWithHelmet />} />
                    <Route path="/dentalimaging" element={<DentalimagWithHelmet />} />
                    <Route path="/prostateimaging" element={<ProstateimagWithHelmet />} />
                    <Route path="/maskuloskelettalimaging" element={<MaskuloskelettalimagWithHelmet />} />
                    <Route path="/vascularimaging" element={<VascularimagWithHelmet />} />

                    <Route path="/log" element={<AdminRouteGuard><Log /></AdminRouteGuard>} />
                    <Route path="/manageappointments" element={<AdminRouteGuard><ManageAppointments /></AdminRouteGuard>} />
                    <Route path="/admin" element={<AdminRouteGuard><Admin /></AdminRouteGuard>} />
                    <Route path="/clients" element={<UserRouteGuard><Clients /></UserRouteGuard>} />
                    <Route path="/clientsservices" element={<UserRouteGuard><ClientServices /></UserRouteGuard>} />
                    <Route path="/doctors" element={<DoctorRouteGuard><Doctors /></DoctorRouteGuard>} />
                    <Route path="/patentserviceslist" element={<DoctorRouteGuard><ClientServicesList /></DoctorRouteGuard>} />
                    <Route path="/accounts" element={<AdminRouteGuard><Accounts /></AdminRouteGuard>} />
                    <Route path="/blockipsmanager" element={<AdminRouteGuard><Blocksipmanager /></AdminRouteGuard>} />
                </Routes>
            </div>
        </Router>
    );
}

const WebsiteSchema = () => (
    <Helmet>
        <script type="application/ld+json">
            {`
                {
                    "@context": "http://schema.org",
                    "@type": "WebSite",
                    "name": "Resonance Diagnostic Imaging Center",
                    "url": "https://ridc.ae"
                }
            `}
        </script>
        <meta name="author" content="Osman amin Daboul" />
        <meta name="creator" content="Osman amin Daboul" />
        <meta name="contributor" content="Osman amin Daboul" />
        <meta name="publisher" content="Resonance Diagnostic Imaging Center" />
    </Helmet>
);

function HomeWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Home Page</title>
                <meta name="description" content="Welcome to Resonance - Your trusted source for medical imaging services in Dubai. Learn about our advanced imaging technologies and comprehensive diagnostic solutions." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Home" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Welcome to Resonance - Your trusted source for medical imaging services in Dubai. Learn about our advanced imaging technologies and comprehensive diagnostic solutions." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Home />
        </>
    );
}

function ServicesWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Services Page</title>
                <meta name="description" content="Discover Resonance's range of medical imaging services, including ultrasound, MRI, CT scan, digital mammography, dental imaging, digital x-ray, and more. Our state-of-the-art facilities and experienced professionals ensure accurate and timely diagnoses in Dubai." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Services" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover Resonance's range of medical imaging services, including ultrasound, MRI, CT scan, digital mammography, dental imaging, digital x-ray, and more. Our state-of-the-art facilities and experienced professionals ensure accurate and timely diagnoses in Dubai." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/services" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Services />
        </>
    );
}

function OurTeamWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Our Team Page</title>
                <meta name="description" content="Meet the dedicated professionals who form the backbone of Resonance Diagnostic Imaging Center in Dubai. Our team comprises experienced radiologists, technologists, nurses, and administrative staff committed to delivering accurate diagnoses and compassionate care. Get to know the faces behind the expertise at Resonance." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Team" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Meet the dedicated professionals who form the backbone of Resonance Diagnostic Imaging Center in Dubai. Our team comprises experienced radiologists, technologists, nurses, and administrative staff committed to delivering accurate diagnoses and compassionate care. Get to know the faces behind the expertise at Resonance." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ourteam" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Ourteam />
        </>
    );
}

function OurNewsWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Our News Page</title>
                <meta name="description" content="Stay updated with the latest news and updates from Resonance Diagnostic Imaging Center. Explore our news section for articles, announcements, and insights on medical imaging advancements, patient stories, healthcare trends, and more. Discover how Resonance is making a difference in Dubai's healthcare landscape." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center News" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Stay updated with the latest news and updates from Resonance Diagnostic Imaging Center. Explore our news section for articles, announcements, and insights on medical imaging advancements, patient stories, healthcare trends, and more. Discover how Resonance is making a difference in Dubai's healthcare landscape." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ournews" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <OurNews />
        </>
    );
}

function GellaryWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Our Gellary Page</title>
                <meta name="description" content="Explore the Gallery of Resonance Diagnostic Imaging Center and view a curated collection of images showcasing our state-of-the-art facilities, advanced medical imaging technologies, and patient care environment in Dubai. Browse through our gallery to get a glimpse of our commitment to excellence in healthcare and patient satisfaction." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Gellary" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the Gallery of Resonance Diagnostic Imaging Center and view a curated collection of images showcasing our state-of-the-art facilities, advanced medical imaging technologies, and patient care environment in Dubai. Browse through our gallery to get a glimpse of our commitment to excellence in healthcare and patient satisfaction." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/gellary" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Gellary />
        </>
    );
}

function AboutusWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Aboutus Page</title>
                <meta name="description" content="Learn more about Resonance Diagnostic Imaging Center and our commitment to providing exceptional medical imaging services in Dubai. Discover our mission, values, and history, and meet the team behind our success. Explore how Resonance is dedicated to delivering accurate diagnoses, compassionate care, and innovative solutions to our patients." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Aboutus" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Learn more about Resonance Diagnostic Imaging Center and our commitment to providing exceptional medical imaging services in Dubai. Discover our mission, values, and history, and meet the team behind our success. Explore how Resonance is dedicated to delivering accurate diagnoses, compassionate care, and innovative solutions to our patients." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/aboutus" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Aboutus />
        </>
    );
}

function AppointmentsWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Appointments Page</title>
                <meta name="description" content="Schedule your appointment at Resonance Diagnostic Imaging Center in Dubai conveniently online. Our booking platform allows you to choose your preferred date and time for medical imaging services, including ultrasound, MRI, CT scan, digital mammography, and more. Book your appointment hassle-free and ensure timely access to our state-of-the-art facilities and experienced professionals." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Appointments" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Schedule your appointment at Resonance Diagnostic Imaging Center in Dubai conveniently online. Our booking platform allows you to choose your preferred date and time for medical imaging services, including ultrasound, MRI, CT scan, digital mammography, and more. Book your appointment hassle-free and ensure timely access to our state-of-the-art facilities and experienced professionals." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/appointments" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Appointments />
        </>
    );
}

//Services

function UltrasoundWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Ultrasound Page</title>
                <meta name="description" content="Discover the ultrasound services offered at Resonance Diagnostic Imaging Center in Dubai. Our ultrasound imaging procedures provide detailed and accurate diagnostic information for various medical conditions, including pregnancy monitoring, abdominal issues, musculoskeletal injuries, and more. Learn about our advanced ultrasound technology and experienced sonographers dedicated to delivering precise and reliable results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Ultrasound" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover the ultrasound services offered at Resonance Diagnostic Imaging Center in Dubai. Our ultrasound imaging procedures provide detailed and accurate diagnostic information for various medical conditions, including pregnancy monitoring, abdominal issues, musculoskeletal injuries, and more. Learn about our advanced ultrasound technology and experienced sonographers dedicated to delivering precise and reliable results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ultrasound" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Ultrasound />
        </>
    );
}

function MriWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Mri Page</title>
                <meta name="description" content="Explore the MRI services provided by Resonance Diagnostic Imaging Center in Dubai. Our MRI (Magnetic Resonance Imaging) technology offers detailed and high-quality images for diagnosing a wide range of medical conditions, including neurological disorders, musculoskeletal injuries, and tumors. Learn about our state-of-the-art MRI equipment and experienced radiologists dedicated to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Mri" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the MRI services provided by Resonance Diagnostic Imaging Center in Dubai. Our MRI (Magnetic Resonance Imaging) technology offers detailed and high-quality images for diagnosing a wide range of medical conditions, including neurological disorders, musculoskeletal injuries, and tumors. Learn about our state-of-the-art MRI equipment and experienced radiologists dedicated to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/mri" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Mri />
        </>
    );
}

function EnjoymriWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Enjoy MRI Page</title>
                <meta name="description" content="Experience the future of MRI technology at Resonance Diagnostic Imaging Center in Dubai with our Enjoy MRI featuring cinematic cameras. Our innovative approach combines cutting-edge MRI scanning with cinematic cameras to provide a unique and immersive imaging experience. Discover how our Enjoy MRI enhances patient comfort and relaxation while delivering high-quality diagnostic images for accurate diagnoses." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Enjoy MRI" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Experience the future of MRI technology at Resonance Diagnostic Imaging Center in Dubai with our Enjoy MRI featuring cinematic cameras. Our innovative approach combines cutting-edge MRI scanning with cinematic cameras to provide a unique and immersive imaging experience. Discover how our Enjoy MRI enhances patient comfort and relaxation while delivering high-quality diagnostic images for accurate diagnoses." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/enjoymriexperience" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Enjoymri />
        </>
    );
}

function CtscanWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Ct Scan Page</title>
                <meta name="description" content="Explore the CT scan services offered at Resonance Diagnostic Imaging Center in Dubai. Our CT (Computed Tomography) scanning technology provides detailed and cross-sectional images of the body, aiding in the diagnosis of various medical conditions such as injuries, tumors, and vascular diseases. Learn about our state-of-the-art CT equipment and experienced radiologists dedicated to delivering accurate and timely diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Ct Scan" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the CT scan services offered at Resonance Diagnostic Imaging Center in Dubai. Our CT (Computed Tomography) scanning technology provides detailed and cross-sectional images of the body, aiding in the diagnosis of various medical conditions such as injuries, tumors, and vascular diseases. Learn about our state-of-the-art CT equipment and experienced radiologists dedicated to delivering accurate and timely diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ctscan" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Ctscan />
        </>
    );
}

function DigitalMammogramWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Digital Mammogram Page</title>
                <meta name="description" content="Discover the digital mammography services provided at Resonance Diagnostic Imaging Center in Dubai. Our digital mammography technology offers high-resolution images for the early detection and diagnosis of breast cancer and other breast abnormalities. Learn about our state-of-the-art equipment, experienced technologists, and commitment to providing accurate and compassionate care for breast health." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Digital Mammogram" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover the digital mammography services provided at Resonance Diagnostic Imaging Center in Dubai. Our digital mammography technology offers high-resolution images for the early detection and diagnosis of breast cancer and other breast abnormalities. Learn about our state-of-the-art equipment, experienced technologists, and commitment to providing accurate and compassionate care for breast health." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/digitalmammogram" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <DigitalMammogram />
        </>
    );
}

function DigitalXrayWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Digital Xray Page</title>
                <meta name="description" content="Explore the digital X-ray services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced digital X-ray technology provides high-quality images for diagnosing various medical conditions, including bone fractures, joint injuries, and pulmonary diseases. Learn about our state-of-the-art equipment, experienced radiographers, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Digital Xray" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the digital X-ray services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced digital X-ray technology provides high-quality images for diagnosing various medical conditions, including bone fractures, joint injuries, and pulmonary diseases. Learn about our state-of-the-art equipment, experienced radiographers, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/digitalxray" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <DigitalXray />
        </>
    );
}

function DintalImaginingWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Dintal Imagining Page</title>
                <meta name="description" content="Explore the dental imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides detailed images for diagnosing dental conditions and planning treatments such as root canals, dental implants, and orthodontic procedures. Learn about our state-of-the-art equipment, experienced dental radiographers, and commitment to providing comprehensive dental care." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Dintal Imagining" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the dental imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides detailed images for diagnosing dental conditions and planning treatments such as root canals, dental implants, and orthodontic procedures. Learn about our state-of-the-art equipment, experienced dental radiographers, and commitment to providing comprehensive dental care." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/dentalimagining" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <DintalImagining />
        </>
    );
}

function BrainheadWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Brainhead and Spine Imaging Page</title>
                <meta name="description" content="Discover the brain, head, and spine imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed and high-resolution images for diagnosing conditions related to the brain, head, and spine, including tumors, neurological disorders, and spinal injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Brainhead and Spine Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover the brain, head, and spine imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed and high-resolution images for diagnosing conditions related to the brain, head, and spine, including tumors, neurological disorders, and spinal injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/brainhead" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Brainhead />
        </>
    );
}

function ChestimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Chest Imaging Page</title>
                <meta name="description" content="Explore the chest imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed images of the chest area, aiding in the diagnosis of conditions such as lung diseases, heart abnormalities, and chest injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Chest Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the chest imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed images of the chest area, aiding in the diagnosis of conditions such as lung diseases, heart abnormalities, and chest injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/chestimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Chestimag />
        </>
    );
}


function BreastimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Breast Imaging Page</title>
                <meta name="description" content="Discover the breast imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized breast imaging technology provides detailed and high-resolution images for the early detection and diagnosis of breast cancer and other breast abnormalities. Learn about our state-of-the-art equipment, experienced technologists, and commitment to providing compassionate and comprehensive breast care." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Breast Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover the breast imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized breast imaging technology provides detailed and high-resolution images for the early detection and diagnosis of breast cancer and other breast abnormalities. Learn about our state-of-the-art equipment, experienced technologists, and commitment to providing compassionate and comprehensive breast care." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/breastimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Breastimag />
        </>
    );
}
function AbdomenimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Abdomen Imaging Page</title>
                <meta name="description" content="Explore the abdomen imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed images of the abdomen, aiding in the diagnosis of conditions such as organ abnormalities, gastrointestinal disorders, and abdominal injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Abdomen Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the abdomen imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed images of the abdomen, aiding in the diagnosis of conditions such as organ abnormalities, gastrointestinal disorders, and abdominal injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/abdomenimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Abdomenimag />
        </>
    );
}

function DentalimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Dental Imaging Page</title>
                <meta name="description" content="Explore the dental imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides detailed images for diagnosing dental conditions and planning treatments such as root canals, dental implants, and orthodontic procedures. Learn about our state-of-the-art equipment, experienced dental radiographers, and commitment to providing comprehensive dental care." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Dental Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the dental imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides detailed images for diagnosing dental conditions and planning treatments such as root canals, dental implants, and orthodontic procedures. Learn about our state-of-the-art equipment, experienced dental radiographers, and commitment to providing comprehensive dental care." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/dentalimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Dentalimag />
        </>
    );
}

function ProstateimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Prostate Imaging Page</title>
                <meta name="description" content="Discover the prostate imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized imaging technology provides detailed images for diagnosing prostate conditions such as prostate cancer, benign prostatic hyperplasia (BPH), and prostatitis. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Prostate Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover the prostate imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized imaging technology provides detailed images for diagnosing prostate conditions such as prostate cancer, benign prostatic hyperplasia (BPH), and prostatitis. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/prostateimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Prostateimag />
        </>
    );
}

function MaskuloskelettalimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Maskuloskelettal Imaging Page</title>
                <meta name="description" content="Explore the musculoskeletal imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed images of the musculoskeletal system, aiding in the diagnosis of conditions such as bone fractures, joint injuries, arthritis, and sports-related injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Maskuloskelettal Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the musculoskeletal imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides detailed images of the musculoskeletal system, aiding in the diagnosis of conditions such as bone fractures, joint injuries, arthritis, and sports-related injuries. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and comprehensive diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/maskuloskelettalimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Maskuloskelettalimag />
        </>
    );
}

function VascularimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Vascular Imaging Page</title>
                <meta name="description" content="Discover the vascular imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized imaging technology provides detailed images of the vascular system, aiding in the diagnosis of conditions such as arterial blockages, aneurysms, and venous disorders. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Vascular Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover the vascular imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized imaging technology provides detailed images of the vascular system, aiding in the diagnosis of conditions such as arterial blockages, aneurysms, and venous disorders. Learn about our state-of-the-art equipment, experienced radiologists, and commitment to delivering accurate and timely diagnostic results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/vascularimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Vascularimag />
        </>
    );
}