import Nav from './components/nav';
import Fot from './components/footer';
import ultraimage from './assets/ULTRASOUNDIMG.webp';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';

export default function Ultrasound() {
  const [lightModeScreen, setLightModeScreen] = useState(true);

  function MyComponent() {
    const {t} = useTranslation();
    let navigate = useNavigate();

    const goToAnotherPage = () => {
      navigate('/services'); 
    };

    useEffect(() => {
      lightModeScreenManager();
      const intervalId = setInterval(lightModeScreenManager, 100);
      return () => {
        clearInterval(intervalId);
      };
  }, []);

    function lightModeScreenManager() {
      let savedValueForMode = localStorage.getItem('Mode');
      if (savedValueForMode == null) {
        setLightModeScreen(true);
        localStorage.setItem('Mode', true);
      } else {
        setLightModeScreen(savedValueForMode === 'true');
      }
    }

    return (
      <div className='services-background-fixer' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
        <Nav />
        <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ultrasoundmaintext1")}</h1>
            <h2 style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundmaintext2")}</h2>
            <img src={ultraimage}/>
          </div>
          <div className="services-parag-ult">
            <p style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundmaindesc")}
            </p>
          </div>
        </div>
        <div className="wrapper">
          <section className="info-section">
            <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ultrasoundmainquestion")}</h2>
            <p className="info-content" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext1")}</p>
            <p className="info-content" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext2")}</p>
          </section>
          <section className="info-section">
            <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ultrasoundtext3")}</h2>
            <ul className="info-content">
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext4")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext5")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext6")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext7")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext8")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext9")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext10")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext11")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext12")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext13")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext14")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext15")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("ultrasoundtext16")}</li>
              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ultrasoundtext17")}</h3>

            </ul>
            <div className="buttons-area-for-infos" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
              <button className="buttons-area-for-infos-btn" onClick={goToAnotherPage}>{t("ultrasoundbackbutton")}</button>
              <button className="buttons-area-for-infos-btn">{t("ultrasoundappointmentbutton")}</button>
            </div>
          </section>
        </div>
        <Fot />
      </div>
    );
  }

  return <MyComponent />;
}
