import React, { useState, useEffect } from 'react';

export default function Log() {

    const [log, setLog] = useState([]);

    useEffect(() => {
        getLogs();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getLogs();
        }, 20000);
        return () => clearInterval(intervalId);
    }, []);

    async function getLogs() {
        try {
            const token = getCookie('token');
            const response = await fetch("https://ridc.ae/getlogs", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
            const data = await response.json();
            setLog(data.log); // Update the state with the fetched data
        } catch (error) {
            console.error("fetch error:", error);
        }
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }


    return (
        <div>
            <table className="log-table">
                <thead>
                    <tr>
                        <th className="log-header">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {log.map((logItem, index) => (
                        <tr key={index} className={index % 2 === 0 ? "log-row-even" : "log-row-odd"}>
                            <td className="log-cell">{logItem}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <button type="button" className="backbuttonforip" onClick={() => window.location.href = '/admin'}>
                Back
            </button>

        </div>
    );
};
