import Nav from './components/nav';
import Fot from './components/footer';
import MRIimage from './assets/MRIIMAGEING.webp';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';

export default function Mri() {
  const [lightModeScreen, setLightModeScreen] = useState(true);
  const {t} = useTranslation();

  function MyComponent() {
    let navigate = useNavigate();

    const goToAnotherPage = () => {
      navigate('/services'); 
    };

    useEffect(() => {
      lightModeScreenManager();
      const intervalId = setInterval(lightModeScreenManager, 100);
      return () => {
        clearInterval(intervalId);
      };
  }, []);

    function lightModeScreenManager() {
      let savedValueForMode = localStorage.getItem('Mode');
      if (savedValueForMode == null) {
        setLightModeScreen(true);
        localStorage.setItem('Mode', true);
      } else {
        setLightModeScreen(savedValueForMode === 'true');
      }
    }
    

    return (
      <div className='services-background-fixer' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
        <Nav />
        <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext1")}</h1>
            <h2 style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext3")}</h2>
            <img src={MRIimage}/>
          </div>
          <div className="services-parag-ult">
            <p style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaindesc")}
            </p>
          </div>
        </div>
        <div className="wrapper">
          <section className="info-section">
            <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimainquestion")}</h2>
            <p className="info-content" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimainquestiondesc")}</p>
           
          </section>
          <section className="info-section">
            <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext")}</h2>
            <ul className="info-content">
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintextdesc1")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintextdesc2")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintextdesc3")} </li>
            </ul>
            <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext2")}</h2>
            <ul className="info-content">
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc1")}</li>
                <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext2desc2")}</h3>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc3")}</li>
              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext2desc4")}</h3>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc5")}</li>
              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext2desc6")}</h3>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc7")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc8")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc9")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc10")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc11")}</li>
              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext2desc12")}</h3>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc13")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc14")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc15")}</li>
              <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("mrimaintext2desc16")}</li>
              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("mrimaintext2desc17")}</h3>

            </ul>
            <div className="buttons-area-for-infos" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
              <button className="buttons-area-for-infos-btn" onClick={goToAnotherPage}>{t("mribackbutton")}</button>
              <button className="buttons-area-for-infos-btn">{t("mriappointmentbutton")}</button>
            </div>
          </section>
        </div>
        <Fot />
      </div>
    );
  }

  return <MyComponent />;
}
