import Nav from './components/nav';
import Fot from './components/footer';
import Dentalimagining from './assets/Dentalimagining.webp';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';

export default function DintalImagining() {
    const [lightModeScreen, setLightModeScreen] = useState(true);
    const {t} = useTranslation();

    function MyComponent() {
        let navigate = useNavigate();

        const goToAnotherPage = () => {
            navigate('/services');
        };

        useEffect(() => {
            lightModeScreenManager();
            const intervalId = setInterval(lightModeScreenManager, 100);
            return () => {
              clearInterval(intervalId);
            };
        }, []);
      
          function lightModeScreenManager() {
            let savedValueForMode = localStorage.getItem('Mode');
            if (savedValueForMode == null) {
              setLightModeScreen(true);
              localStorage.setItem('Mode', true);
            } else {
              setLightModeScreen(savedValueForMode === 'true');
            }
          }

        return (
            <div className='services-background-fixer' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <Nav />
                <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                        <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("dentalimagingmaintext1")}</h1>
                        <h2 style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingmaintext2")}</h2>
                        <img src={Dentalimagining} />
                    </div>
                    <div className="services-parag-ult">
                        <p style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingmaindesc")}
                        </p>
                    </div>
                </div>
                <div className="wrapper">
                    <section className="info-section">
                        <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("dentalimagingmainquestion")}</h2>
                        <p className="info-content" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingtext1")}</p>
                    </section>
                    <section className="info-section">
                        <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("dentalimagingtext2")}</h2>
                        <ul className="info-content">
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingtext3")}</li>
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingtext4")}</li>
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingtext5")}</li>
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingtext6")}</li>
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingtext7")}</li>
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("dentalimagingtext8")}</li>
                        </ul>
                       
                        <div className="buttons-area-for-infos" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                            <button className="buttons-area-for-infos-btn" onClick={goToAnotherPage}>{t("dentalimagingbackbutton")}</button>
                            <button className="buttons-area-for-infos-btn">{t("dentalimagingappointmentbutton")}</button>
                        </div>
                    </section>
                </div>
                <Fot />
            </div>
        );
    }

    return <MyComponent />;
};
