import React, { useState, useEffect } from 'react';

export default function ClientServices() {
    const BASE_URL = 'https://ridc.ae';
    const [clientServices, setClientServices] = useState([]);

    useEffect(() => {
        loadClientServices();
    }, []);

    const loadClientServices = async () => {
        try {
            const token = getCookie('token');
            const response = await fetch(`${BASE_URL}/getservicesforclient`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
            const data = await response.json();
            console.log("alasd:", data);
            setClientServices(data.services);
        } catch (error) {
            console.error('Error loading client services:', error);
        }
    };

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    

    return (

        <div>
            <div className='table-header'>
                    <h1>You can find here all services you have done</h1>
                </div>
            <table className="tableofacleintservices">
                
                <thead className='tableheaderclient'>
                    <tr>
                        <th>Service</th>
                        <th>Date</th>
                        <th>Price</th>
                        <th>Files</th>
                    </tr>
                </thead>
                <tbody className='tableheaderclient-tbody'>
                    {clientServices.map((service) => (
                        <tr key={service.id}>
                            <td>{service.service_name}</td>
                            <td>{new Date(service.date).toLocaleString()}</td>
                            <td>
                            {service.price && service.price.trim() !== '' ? (
                                    <h7>{service.price}</h7>
                                ) : (
                                    'N/A'
                                )}
                            </td>
                            <td>
                                {service.filelink && service.filelink.trim() !== '' ? (
                                    <a href={service.filelink} target="_blank" rel="noopener noreferrer">Files</a>
                                ) : (
                                    'N/A'
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <button type="button" className="backbuttonforip" onClick={() => window.location.href = '/clients'}>
                Back
            </button>

        </div>

    );
}