import React, { useState, useEffect } from 'react';

export default function BlocksIpManager() {
    const [blockedIps, setBlockedIps] = useState([]);
    const [newIp, setNewIp] = useState('');
    
    const fetchBlockedIps = async () => {
        try {
            const token = getCookie('token');
            const response = await fetch('https://ridc.ae/getblockedips', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.ok) {
                const data = await response.json();
                setBlockedIps(data.blockedips);
            } else {
                console.error('Failed to fetch blocked IPs:', response.status);
            }
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
        } catch (error) {
            console.error('Error fetching blocked IPs:', error);
        }
    };

    // Function to handle adding a new blocked IP
    const handleAddIp = async () => {

        if(newIp.length > 0 ){
        try {
            const token = getCookie('token');
            const response = await fetch('https://ridc.ae/addblockip', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ip: newIp })
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message);
                // Refresh the blocked IPs list
                fetchBlockedIps();
                // Clear the input field
                setNewIp('');
            } else {
                console.error('Failed to add blocked IP:', response.status);
            }
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
        } catch (error) {
            console.error('Error adding blocked IP:', error);
        }
    }else{
        alert("Please put a IP!");
    }
    };

    // Function to handle deleting a blocked IP
    const handleDeleteIp = async (id) => {
        try {
            const token = getCookie('token');
            const response = await fetch(`https://ridc.ae/deleteblockedip/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message);
                // Refresh the blocked IPs list
                fetchBlockedIps();
            } else {
                console.error('Failed to delete blocked IP:', response.status);
            }
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
        } catch (error) {
            console.error('Error deleting blocked IP:', error);
        }
    };

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    // Fetch blocked IPs when the component mounts
    useEffect(() => {
        fetchBlockedIps();
    }, []);

    return (
        <div className="blocks-ip-manager">
            <h1>Manage Blocked IPs</h1>
            
            {/* Form to add new blocked IP */}
            <div className="form">
                <input
                    type="text"
                    value={newIp}
                    onChange={(e) => setNewIp(e.target.value)}
                    placeholder="Enter IP address"
                />
                <button onClick={handleAddIp}>Add IP</button>
            </div>
            
            {/* Table to display blocked IPs */}
            <table className="blockmanagertable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>IP Address</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {blockedIps.map((ip) => (
                        <tr key={ip.id}>
                            <td>{ip.id}</td>
                            <td>{ip.ip}</td>
                            <td>
                                <button onClick={() => handleDeleteIp(ip.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" className="backbuttonforip" onClick={() => window.location.href = '/admin'}>
                Back
            </button>
        </div>
    );
}
