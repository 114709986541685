import React, { useState, useEffect } from 'react';

export default function ManageAppointments() {
    const [service, setService] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [time, setTime] = useState('');
    const [RequestPage, setRequestPage] = useState(false);
    const [RequestButtonPage, setRequestButtonPage] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [requestAppointments, setRequestAppointments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('service'); // State variable for search type
    const [buttonColorService, setButtonColorService] = useState('#a7a7a7');
    const [buttonColorName, setButtonColorName] = useState('#a7a7a7');
    const [buttonColorPhone, setButtonColorPhone] = useState('#a7a7a7');
    const [buttonColorEmail, setButtonColorEmail] = useState('#a7a7a7');
    const [buttonColorTime, setButtonColorTime] = useState('#a7a7a7');

    useEffect(() => {
        loadAppointments();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleRequestButtonPage();
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (inputsChecker()) {
            try {
                const token = getCookie('token');
                const response = await fetch("https://ridc.ae/addappointment", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ service, name, phone, email, notes, time })
                });
                if (!response.ok) {
                    if (response.status === 403) {
                        console.log("Error Happen, Please make sure you are login!");
                    } else {
                        throw new Error('Network response was not ok');
                    }
                    return;
                }

                const data = await response.json();
                if (data && data.message === "Appointment added successfully") {
                    console.log("Appointment added successfully");
                    clearInputs();
                    loadAppointments();
                } else {
                    console.error("Unexpected response from server:", data);
                }
            } catch (error) {
                console.error("fetch error:", error);
            }
        }
    };

    const handleServiceChange = (event) => {
        setService(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    const handleTimeChange = (event) => {
        // Format time
        const formattedTime = event.target.value.replace('T', ' ');
        setTime(formattedTime);
    };

    const handleRequestPage = () => {
        setRequestPage(!RequestPage);
        if (!RequestPage) {
            loadRequestAppointments();
        }
    };

    const handleRequestButtonPage = async () => {
        const data = await getRequestAppointments();
        console.log('Data from getRequestAppointments:', data);
        if (data && data.requestappointments && Array.isArray(data.requestappointments) && data.requestappointments.length > 0) {
            console.log('Setting RequestButtonPage to true');
            setRequestButtonPage(true);
        } else {
            console.log('Setting RequestButtonPage to false');
            setRequestButtonPage(false);
        }
    };

    async function loadAppointments() {
        try {
            const data = await getAppointments();
            if (data) {
                setAppointments(data.appointments);
            }
        } catch (error) {
            console.error("Error loading appointments:", error);
        }
    }

    async function getAppointments() {
        try {
            const token = getCookie('token');
            const response = await fetch("https://ridc.ae/appointmentsmanager", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
            const data = await response.json();
            console.log(data);
            return data;
        } catch (error) {
            console.error("fetch error:", error);
            return null;
        }
    }

    async function loadRequestAppointments() {
        try {
            const data = await getRequestAppointments();
            if (data && data.requestappointments) {
                setRequestAppointments(data.requestappointments);
                handleRequestButtonPage();
            }
        } catch (error) {
            console.error("Error loading requested appointments:", error);
        }
    }

    async function getRequestAppointments() {
        try {
            const token = getCookie('token');
            const response = await fetch("https://ridc.ae/requestappointmentsmanager", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
            const data = await response.json();
            console.log('API Response:', data);
            return data;
        } catch (error) {
            console.error("fetch error:", error);
            return null;
        }
    }

    const handleDelete = async (id) => {
        try {
            const token = getCookie('token');
            const response = await fetch(`https://ridc.ae/deleteappointment/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
            loadAppointments();
            console.log('Appointment deleted successfully');
        } catch (error) {
            console.error('Error deleting appointment:', error);
        }
    };

    const handleRequestDelete = async (id) => {
        try {
            const token = getCookie('token');
            const response = await fetch(`https://ridc.ae/deleterequestappointment/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                if (response.status === 403) {
                    console.log("Error Happen, Please make sure you are login!");
                } else {
                    throw new Error('Network response was not ok');
                }
                return;
            }
            loadRequestAppointments();
            console.log('Request appointment deleted successfully');
        } catch (error) {
            console.error('Error deleting request appointment:', error);
        }
    };

    const clearInputs = () => {
        setService('');
        setName('');
        setPhone('');
        setEmail('');
        setNotes('');
        setTime('');
    };

    const inputsChecker = () => {
        if (service.length <= 1 || name.length <= 1 || phone.length <= 1 || email.length <= 1 || notes.length <= 1 || time.length <= 1) {
            alert('All fields must have at least 1 character');
            return false;
        } else {
            return true;
        }
    };

    const handleBack = () => {
        window.location.href = '/admin';
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    function searchInputs(num) {
        // Reset button colors
        resetSearchInputsColors();
        // Set the selected button color
        switch (num) {
            case 1:
                setButtonColorService('#007BFF');
                setSearchType('service');
                break;
            case 2:
                setButtonColorName('#007BFF');
                setSearchType('name');
                break;
            case 3:
                setButtonColorPhone('#007BFF');
                setSearchType('phone');
                break;
            case 4:
                setButtonColorEmail('#007BFF');
                setSearchType('email');
                break;
            case 5:
                setButtonColorTime('#007BFF');
                setSearchType('time');
                break;
            default:
                break;
        }
    }

    function resetSearchInputsColors() {
        setButtonColorService('#a7a7a7');
        setButtonColorName('#a7a7a7');
        setButtonColorPhone('#a7a7a7');
        setButtonColorEmail('#a7a7a7');
        setButtonColorTime('#a7a7a7');
    }

    // Filter appointments based on search term and search type
    const filteredAppointments = appointments.filter((appointment) => {
        const value = appointment[searchType]?.toLowerCase() || '';
        return value.includes(searchTerm.toLowerCase());
    });

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    return (
        <div className="fullappointments">
            <form onSubmit={handleSubmit}>
                <div className="appointmentsinput">
                    <select
                        id="service"
                        name="service"
                        value={service}
                        onChange={handleServiceChange}
                    >
                        <option value="ultrasound">Ultrasound</option>
                        <option value="ctscan">CT scan</option>
                        <option value="mri">MRI</option>
                        <option value="digitalxray">Digital X-ray</option>
                        <option value="digitalmomogram">Digital momogram</option>
                        <option value="dentalimagining">Dental imagining</option>
                    </select>
                    <input type="text" placeholder="Name" onChange={handleNameChange} value={name} />
                    <input type="text" placeholder="Phone" onChange={handlePhoneChange} value={phone} />
                    <input type="text" placeholder="Email" onChange={handleEmailChange} value={email} />
                    <input type="text" placeholder="Notes" onChange={handleNotesChange} value={notes} />
                    <input type="datetime-local" id="meeting-time" name="meeting-time" placeholder="Time" onChange={handleTimeChange} value={time} className='datingmenu' />
                    <button type="submit">Create new appointment</button>
                </div>
            </form>
            {RequestButtonPage && (
                <button className='requestappointmentbutton' onClick={handleRequestPage}>New requested appointment!</button>
            )}
            {RequestPage ? (
                <div className='requestpagefull'>
                    <div className='requestpagebox'>
                        <a className="close-button" onClick={handleRequestPage}>x</a>
                        <table className="tableofrequestedappointments">
                            <thead>
                                <tr>
                                    <th>Service</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>IP</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requestAppointments && requestAppointments.map(requestAppointment => (
                                    <tr key={requestAppointment.id}>
                                        <td>{requestAppointment.service}</td>
                                        <td>{requestAppointment.name}</td>
                                        <td>{requestAppointment.phone}</td>
                                        <td>{requestAppointment.email}</td>
                                        <td>{requestAppointment.message}</td>
                                        <td>{requestAppointment.ip}</td>
                                        {<div className='deleterequestbox'><button onClick={() => handleRequestDelete(requestAppointment.id)} className="delete-requestbutton">Delete</button></div>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : null}
            <hr />
            <input type="text" placeholder={`Search by ${searchType}`} onChange={handleSearchChange} value={searchTerm} className="searchinput" />
            <div className='search'>
                <button onClick={() => searchInputs(1)} style={{ backgroundColor: buttonColorService }}>Service</button>
                <button onClick={() => searchInputs(2)} style={{ backgroundColor: buttonColorName }}>Name</button>
                <button onClick={() => searchInputs(3)} style={{ backgroundColor: buttonColorPhone }}>Phone</button>
                <button onClick={() => searchInputs(4)} style={{ backgroundColor: buttonColorEmail }}>Email</button>
                <button onClick={() => searchInputs(5)} style={{ backgroundColor: buttonColorTime }}>Time</button>
            </div>
            <div className="table-container">
                <table className="tableofappointments">
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Notes</th>
                            <th>Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAppointments && filteredAppointments.map(appointment => (
                            <tr key={appointment.id}>
                                <td>{appointment.service}</td>
                                <td>{appointment.name}</td>
                                <td>{appointment.phone}</td>
                                <td>{appointment.email}</td>
                                <td>{appointment.notes}</td>
                                <td>{appointment.time}</td>
                                <td>
                                    <button onClick={() => handleDelete(appointment.id)} className="delete-button">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button type="button" className='backbutton' onClick={handleBack}>Back</button>
        </div>
    );
}
