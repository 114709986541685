import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const UserRouteGuard = ({ children }) => {
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getCookie('token');

    if (token) {
      // Send token to server for verification
      fetch("https://ridc.ae/verifyTokenDoctorOrAdmin", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.isUser) {
            setIsUser(true);
          }
          setLoading(false); // Set loading to false when request completes
        })
        .catch(error => {
          console.error('Error verifying token:', error);
          setLoading(false); // Set loading to false on error
        });
    } else {
      setLoading(false); // Set loading to false if no token is found
    }
  }, []);

  if (loading) {
    // Return loading indicator or null while waiting for verification
    return null;
  }

  if (isUser) {
    console.log("Return to user page.");
    return <>{children}</>;
  } else {
    console.log("Return to login page.");
    return <Navigate to="/login" />;
  }
};

export default UserRouteGuard;

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
