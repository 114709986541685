import Nav from './components/nav';
import Fot from './components/footer';
import mriimag from './assets/news/MRI2.webp';
import mripros from './assets/news/MRI_Prostate-760x581.webp';
import momophot from './assets/news/Mamo.webp';
import newphot from './assets/news/New-time-760x581.webp';
import brestpho from './assets/news/breast-cancer1-760x581.webp';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function OurNews() {
    const {t} = useTranslation();
    const [animationTriggered, setAnimationTriggered] = useState(false);
    const [lightModeScreen, setLightModeScreen] = useState(true);
    const refCardContainer = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !animationTriggered) {
                    setAnimationTriggered(true);
                    const cards = entry.target.querySelectorAll('.card-news');
                    cards.forEach((card) => {
                        card.classList.add('animate-visible');
                    });
                }
            });
        }, {
            threshold: 0.5 // Trigger animation when 50% of the element is visible
        });

        // Start observing when components are mounted
        observer.observe(refCardContainer.current);

        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
    }, [animationTriggered]);

    useEffect(() => {
        lightModeScreenManager();
        const intervalId = setInterval(lightModeScreenManager, 100);
        return () => {
          clearInterval(intervalId);
        };
    }, []);

    function lightModeScreenManager() {
        let savedValueForMode = localStorage.getItem('Mode');
        if (savedValueForMode == null) {
          setLightModeScreen(true);
          localStorage.setItem('Mode', true);
        } else {
          setLightModeScreen(savedValueForMode === 'true');
        }
      }
      
    return (
        <>
            <Nav />
            <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}> 
                <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <h1 style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("ournewsmaintext1")} <span className="highlight">{t("ournewsmaintext2")}</span></h1>
                </div>
            </div>
            <div ref={refCardContainer} className='card-all-news' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <div className="card-news fade-in" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' , boxShadow: lightModeScreen ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : '0 4px 8px 0 rgba(255, 255, 255, 0.2)'}}>
                    <img src={mriimag} alt="MRI" />
                    <div className="card-news-container">
                        <h4 style={{ color: lightModeScreen ? 'black' : 'white' }}><b>{t("ournewscardmain1")}</b></h4>
                        <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ournewscarddesc1")}</p>
                    </div>
                    <div className='card-news-container-date'>
                        <h4 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscardtype1")}</h4>
                        <h5 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscarddate1")}</h5>
                    </div>
                </div>

                <div className="card-news fade-in" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' , boxShadow: lightModeScreen ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : '0 4px 8px 0 rgba(255, 255, 255, 0.2)'}}>
                    <img src={momophot} alt="MRI" />
                    <div className="card-news-container">
                        <h4 style={{ color: lightModeScreen ? 'black' : 'white' }}><b>{t("ournewscardmain2")}</b></h4>
                        <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ournewscarddesc2")}</p>
                    </div>
                    <div className='card-news-container-date'>
                        <h4 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscardtype2")}</h4>
                        <h5 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscarddate2")}</h5>
                    </div>
                </div>

                <div className="card-news fade-in" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' , boxShadow: lightModeScreen ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : '0 4px 8px 0 rgba(255, 255, 255, 0.2)'}}>
                    <img src={mripros} alt="MRI" />
                    <div className="card-news-container">
                        <h4 style={{ color: lightModeScreen ? 'black' : 'white' }}><b>{t("ournewscardmain3")}</b></h4>
                        <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ournewscarddesc3")}</p>
                    </div>
                    <div className='card-news-container-date'>
                        <h4 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscardtype3")}</h4>
                        <h5 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscarddate3")}</h5>
                    </div>
                </div>

                <div className="card-news fade-in" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' , boxShadow: lightModeScreen ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : '0 4px 8px 0 rgba(255, 255, 255, 0.2)'}}>
                    <img src={newphot} alt="MRI" />
                    <div className="card-news-container">
                        <h4 style={{ color: lightModeScreen ? 'black' : 'white' }}><b>{t("ournewscardmain4")}</b></h4>
                        <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ournewscarddesc4")}</p>
                    </div>
                    <div className='card-news-container-date'>
                        <h4 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscardtype4")}</h4>
                        <h5 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscarddate4")}</h5>
                    </div>
                </div>

                <div className="card-news fade-in" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' , boxShadow: lightModeScreen ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : '0 4px 8px 0 rgba(255, 255, 255, 0.2)'}}>
                    <img src={brestpho} alt="MRI" />
                    <div className="card-news-container">
                        <h4 style={{ color: lightModeScreen ? 'black' : 'white' }}><b>{t("ournewscardmain5")}</b></h4>
                        <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ournewscarddesc5")}</p>
                    </div>
                    <div className='card-news-container-date'>
                        <h4 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscardtype5")}</h4>
                        <h5 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscarddate5")}</h5>
                    </div>
                </div>

                <div className="card-news fade-in" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' , boxShadow: lightModeScreen ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)' : '0 4px 8px 0 rgba(255, 255, 255, 0.2)'}}>
                    <img src="mri_image.jpg" alt="MRI" />
                    <div className="card-news-container">
                        <h4 style={{ color: lightModeScreen ? 'black' : 'white' }}><b>{t("ournewscardmain6")}</b></h4>
                        <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}></p>
                    </div>
                    <div className='card-news-container-date'>
                        <h4 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscardtype6")}</h4>
                        <h5 style={{ color: lightModeScreen ? '#333' : '#c7c3c3' }}>{t("ournewscarddate6")}</h5>
                    </div>
                </div>

            </div>
            <Fot />
        </>
    );
}


