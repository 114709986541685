import React, { useState, useEffect } from "react";

export default function Accounts() {
  const BASE_URL = "https://ridc.ae";

  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [servicesMenu, setServicesMenu] = useState("Ultrasound");
  const [price, setPrice] = useState("");
  const [rank, setRank] = useState("client");
  const [newPassword, setNewPassword] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [clientServices, setClientServices] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [usernameOfServiceClicked, setUsernameOfServiceClicked] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [time, setTime] = useState("");
  const [showServices, setShowServices] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");

  // Load accounts when the component first mounts
  useEffect(() => {
    loadAccounts();
    loadDoctors();
  }, []);

  // Load client services whenever `usernameOfServiceClicked` changes

  useEffect(() => {
    if (usernameOfServiceClicked) {
      loadClientServices();
    }
  }, [usernameOfServiceClicked]);

  const loadDoctors = async () => {
    try {
      const token = getCookie("token");
      const response = await fetch(`${BASE_URL}/getdoctors`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 403) {
          console.log("Error Happened. Please make sure you are logged in!");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }
      const data = await response.json();
      setDoctors(data.doctors);
    } catch (error) {
      console.error("Error loading doctors:", error);
    }
  };

  const loadAccounts = async () => {
    try {
      const token = getCookie("token");

      const response = await fetch(`${BASE_URL}/accountsmanager`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          console.log("Error Happen, Please make sure you are login!");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }

      const data = await response.json();
      setAccounts(data.accounts);
    } catch (error) {
      console.error("Error loading accounts:", error);
    }
  };

  const loadClientServices = async () => {
    try {
      const token = getCookie("token");
      const response = await fetch(
        `${BASE_URL}/getservices/${usernameOfServiceClicked}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 403) {
          console.log("Error Happen, Please make sure you are login!");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }
      const data = await response.json();
      console.log("alasd:", data);
      setClientServices(data.services);
    } catch (error) {
      console.error("Error loading client services:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputsChecker()) {
      try {
        const token = getCookie("token");
        const response = await fetch(`${BASE_URL}/addaccount`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ fullname, username, password, rank }),
        });
        if (!response.ok) {
          if (response.status === 403) {
            console.log("Error Happen, Please make sure you are login!");
          } else {
            alert("Error: There might be a username like the one you provided");
            throw new Error("Network response was not ok");
          }
          return;
        }

        const data = await response.json();
        if (data.message === "Account added successfully") {
          console.log("Account added successfully");
          clearInputs();
          loadAccounts();
        } else {
          console.error("Unexpected response from server:", data);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }
  };

  const inputsChecker = () => {
    if (
      [fullname, username, password, rank].some((input) => input.length <= 1)
    ) {
      alert("All fields must have at least 1 character");
      return false;
    }
    return true;
  };

  const clearInputs = () => {
    setFullname("");
    setUsername("");
    setPassword("");
    setRank("");
    setNewPassword("");
  };

  const handleFullnameChange = (event) => setFullname(event.target.value);
  const handleFileLinkChange = (event) => setFileLink(event.target.value);
  const handleUsernameChange = (event) => setUsername(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleRankChange = (event) => setRank(event.target.value);
  const handleNewPasswordChange = (event) => setNewPassword(event.target.value);
  const handleSearchQueryChange = (event) => setSearchQuery(event.target.value);
  const handleTimeChange = (event) =>
    setTime(event.target.value.replace("T", " "));
  const handleServicesMenuChange = (event) =>
    setServicesMenu(event.target.value);
  const handlePriceChange = (event) => setPrice(event.target.value);

  const handleDelete = async (id) => {
    try {
      const token = getCookie("token");
      const response = await fetch(`${BASE_URL}/deleteaccount/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 403) {
          console.log("Error Happen, Please make sure you are login!");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }
      loadAccounts();
      console.log("Account deleted successfully");
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const handleDeleteForService = async (id) => {
    console.log("Deleting service with ID:", id);

    if (!id) {
      console.error("ID is undefined");
      return;
    }

    try {
      const token = getCookie("token");
      const response = await fetch(
        `${BASE_URL}/deleteservicefromclient/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 403) {
          console.log("Error Happen, Please make sure you are login!");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }
      loadClientServices();
      console.log("Service deleted successfully");
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  };

  const handleServices = async (username) => {
    setUsernameOfServiceClicked(username);
    setShowServices(true);
  };

  const handleReset = async () => {
    if (!selectedAccountId) {
      console.error("No account selected for password reset");
      return;
    }

    try {
      const token = getCookie("token");
      const response = await fetch(
        `${BASE_URL}/resetaccountpassword/${selectedAccountId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ password: newPassword }),
        }
      );
      if (!response.ok) {
        if (response.status === 403) {
          console.log("Error Happen, Please make sure you are login!");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }
      loadAccounts();
      console.log("Account password reset successfully");
      alert("Account password reset successfully");
    } catch (error) {
      console.error("Error resetting account password:", error);
    }
  };

  const handleSubmitServiceForAccount = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Validate that all fields are provided
    if (!servicesMenu || !time) {
      console.error("All fields are required.");
      alert("All fields are required.");
      return;
    }

    try {
      const token = getCookie("token");
      const response = await fetch(`${BASE_URL}/addservicetoaccount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          username: usernameOfServiceClicked,
          service: servicesMenu,
          time: time,
          price: price,
          fileLink: fileLink,
          doctor: selectedDoctor,
        }),
      });

      if (!response.ok) {
        if (response.status === 403) {
          console.log("Error Happen, Please make sure you are login!");
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }

      const data = await response.json();
      console.log("Service added successfully:", data.message);
      resetServiceForm();
      setShowServices(false);
      loadClientServices();
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const handleDoctorChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  const resetServiceForm = () => {
    setServicesMenu("Ultrasound");
    setPrice("");
    setTime("");
    setFileLink("");
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  return (
    <div className="fullaccounts">
      <form onSubmit={handleSubmit}>
        <div className="accountsinput">
          <input
            type="text"
            placeholder="Full name"
            onChange={handleFullnameChange}
            value={fullname}
          />
          <input
            type="text"
            placeholder="Username"
            onChange={handleUsernameChange}
            value={username}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange}
            value={password}
          />
          <select onChange={handleRankChange} value={rank}>
            <option value="client">client</option>
            <option value="admin">admin</option>
            <option value="doctor">doctor</option>
          </select>
          <button type="submit">Create new account</button>
        </div>
      </form>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search accounts..."
          onChange={handleSearchQueryChange}
          value={searchQuery}
        />
      </div>

      <div className="table-container">
        <table className="tableofaccounts">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Username</th>
              <th>Rank</th>
              <th>Reset</th>
              <th>Services</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {accounts
              .filter(
                (account) =>
                  account.fullname
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                  account.username
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                  account.rank.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((account) => (
                <tr key={account.id}>
                  <td>{account.fullname}</td>
                  <td>{account.username}</td>
                  <td>{account.rank}</td>
                  <td>
                    <input
                      className="resetpasswordinput"
                      type="password"
                      placeholder="New password"
                      onChange={handleNewPasswordChange}
                    />
                    <button
                      onClick={() => {
                        setSelectedAccountId(account.id);
                        handleReset();
                      }}
                      className="delete-button"
                    >
                      Reset Password
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleServices(account.username)}
                      className="delete-button"
                    >
                      Services
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDelete(account.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <button
        type="button"
        className="backbutton"
        onClick={() => (window.location.href = "/admin")}
      >
        Back
      </button>

      {showServices && (
        <div className="servicesmenu">
          <form onSubmit={handleSubmitServiceForAccount}>
            <div className="accountsinput">
              <select
                id="service"
                name="service"
                value={servicesMenu}
                onChange={handleServicesMenuChange}
              >
                <option value="Ultrasound">Ultrasound</option>
                <option value="CT scan">CT scan</option>
                <option value="MRI">MRI</option>
                <option value="Digital X-ray">Digital X-ray</option>
                <option value="Digital mammogram">Digital mammogram</option>
                <option value="Dental imaging">Dental imaging</option>
              </select>
              <input
                type="datetime-local"
                id="meeting-time"
                name="meeting-time"
                className="datingmenu"
                onChange={handleTimeChange}
                value={time}
              />
              <input
                type="text"
                placeholder="Price"
                onChange={handlePriceChange}
                value={price}
              />
              <input
                type="text"
                placeholder="File Link"
                onChange={handleFileLinkChange}
                value={fileLink}
              />
              <select
                id="doctor"
                name="doctor"
                value={selectedDoctor}
                onChange={handleDoctorChange}
              >
                <option value="">Select Doctor</option>
                {doctors.map((doctor, index) => (
                  <option key={index} value={doctor}>
                    {doctor}
                  </option>
                ))}
              </select>
              <button type="submit">Add</button>
            </div>
            <table className="tableofacleintservices">
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Date</th>
                  <th>Doctor</th>
                  <th>Price</th>
                  <th>Files</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {clientServices.map((service) => (
                  <tr key={service.id}>
                    <td>{service.service_name}</td>
                    <td>{new Date(service.date).toLocaleString()}</td>
                    <td>{service.doctor || "N/A"}</td>
                    <td>
                      {service.price && service.price.trim() !== "" ? (
                        <h4>{service.price}</h4>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {service.filelink && service.filelink.trim() !== "" ? (
                        <a
                          href={service.filelink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Files
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => handleDeleteForService(service.id)}
                        className="delete-button"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={() => {
                setShowServices(false);
              }}
              className="backbutton"
            >
              Back
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
