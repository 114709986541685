import Nav from './components/nav';
import Fot from './components/footer';
import brainimg1 from './assets/body/BRAIN-HEAD-SPINE-IMAGING-image1-300x234-1.webp';
import brainimg2 from './assets/body/BRAIN-HEAD-SPINE-IMAGING-image2-179x300-1.webp';
import brainimg9 from './assets/body/Breast-Ultrasound-300x275-1.webp';
import brainimg4 from './assets/body/Carotid-Doppler-380x267-1-300x211.webp';
import brainimg5 from './assets/body/chest-x-ray-300x274.webp';
import brainimg6 from './assets/body/CRL_Crown_rump_lengh_12_weeks_ecografia_Dr._Wolfgang_Moroder-300x235-1.webp';
import brainimg7 from './assets/body/dental-circle-ridc-01-200x200.webp';
import brainimg8 from './assets/body/Liver-MRI.webp';
import brainimg3 from './assets/body/LLAGUNO^MELVIN_MR_3_14-300x285-1.webp';
import brainimg10 from './assets/body/MAmmogram-190x442-1-129x300.webp';
import brainimg11 from './assets/body/MRA-Aorta-190x220-1.webp';
import brainimg12 from './assets/body/MRA-lower-limbs-113x300-1.webp';
import brainimg13 from './assets/body/MRA-neck-300x296-1.webp';
import brainimg14 from './assets/body/MR-cholangiography.webp';
import brainimg15 from './assets/body/MRI-@-prostate-300x300-1.webp';
import brainimg16 from './assets/body/MRI-enterography-228x300.webp';
import brainimg17 from './assets/body/PastedGraphic-1-300x233.webp';
import brainimg18 from './assets/body/Renal-CT-angio-300x283-1.webp';
import brainimg19 from './assets/body/services-body-abdominal-200x200.webp';
import brainimg20 from './assets/body/services-body-brain-spine-200x200.webp';
import brainimg21 from './assets/body/services-body-chest-200x200.webp';
import brainimg22 from './assets/body/services-body-mammography-200x200.webp';
import brainimg23 from './assets/body/services-body-musculoskeletal-200x200.webp';
import brainimg24 from './assets/body/services-body-prostate-1-200x200.webp';
import brainimg25 from './assets/body/services-body-prostate-200x200.webp';
import brainimg26 from './assets/body/virtualcolonoscopy.webp';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

export default function Chestimag() {
  const [lightModeScreen, setLightModeScreen] = useState(true);
  const {t} = useTranslation();

  function MyComponent() {
    let navigate = useNavigate();

    const goToAnotherPage = () => {
      navigate('/services'); 
    };

    useEffect(() => {
      lightModeScreenManager();
      const intervalId = setInterval(lightModeScreenManager, 100);
      return () => {
        clearInterval(intervalId);
      };
  }, []);

    function lightModeScreenManager() {
      let savedValueForMode = localStorage.getItem('Mode');
      if (savedValueForMode == null) {
        setLightModeScreen(true);
        localStorage.setItem('Mode', true);
      } else {
        setLightModeScreen(savedValueForMode === 'true');
      }
    }

    return (
      <div className='services-background-fixer' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
        <Nav />
        <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("chestimagingmaintext1")}</h1>
            <h2 style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("chestimagingmaintext2")}</h2>
            <div className='services-header-ult-img-container'>
            <img src={brainimg5}/>
            </div>
          </div>
          <div className="services-parag-ult">
            <p style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("chestimagingmaindesc")}
            </p>
          </div>
        </div>
        <div className="wrapper">
        <section className="info-section">
                        <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("chestimagingmaintext3")}</h2>
                    </section>
        <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
              <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("chestimagingmaintext4")}</h1>
              <p style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("chestimagingmaintext4desc1")} <br/> {t("chestimagingmaintext4desc2")} <br/> {t("chestimagingmaintext4desc3")}
              </p>
             
            </div>
            
            <div className="services-parag-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
             

            </div>
       </div>
        
          
            <div className="buttons-area-for-infos" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
              <button className="buttons-area-for-infos-btn" onClick={goToAnotherPage}>{t("chestimagingbackbutton")}</button>
              <button className="buttons-area-for-infos-btn">{t("chestimagingappointmentbutton")}</button>
            </div>
          
        </div>
        <Fot />
      </div>
    );
  }

  return <MyComponent />;
}
