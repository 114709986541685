import Nav from './components/nav';
import Fot from './components/footer';
import Ctscanimagin from './assets/Ctscanimagin.webp';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';

export default function CtScan() {
    const [lightModeScreen, setLightModeScreen] = useState(true);
    const {t} = useTranslation();

    function MyComponent() {
        let navigate = useNavigate();

        const goToAnotherPage = () => {
            navigate('/services');
        };

        useEffect(() => {
            lightModeScreenManager();
            const intervalId = setInterval(lightModeScreenManager, 100);
            return () => {
              clearInterval(intervalId);
            };
        }, []);
      
          function lightModeScreenManager() {
            let savedValueForMode = localStorage.getItem('Mode');
            if (savedValueForMode == null) {
              setLightModeScreen(true);
              localStorage.setItem('Mode', true);
            } else {
              setLightModeScreen(savedValueForMode === 'true');
            }
          }

        return (
            <div className='services-background-fixer' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <Nav />
                <div className="services-header-area-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <div className="services-header-ult" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                        <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("csscanmaintext1")}</h1>
                        <h2 style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("csscanmaintext2")}</h2>
                        <img src={Ctscanimagin} />
                    </div>
                    <div className="services-parag-ult">
                        <p style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("csscanmaindesc")}
                        </p>
                    </div>
                </div>
                <div className="wrapper">
                    <section className="info-section">
                        <h2 className="info-header" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("csscanmainquestion")}</h2>
                        <p className="info-content" style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("csscantext1")}
                            <br/>
                            <br/>
                            <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("csscantext2")}</h3>
                        </p>
                        <ul className="info-content">
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("csscantext3")}</li>
                       <br/>
                        <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("csscantext4")}</h3>
                        </ul>
                        <ul className="info-content">
                            <li style={{ color: lightModeScreen ? '#333' : '#dbd7d7' }}>{t("csscantext5")}</li>
                        </ul>
                    </section>





                    <div className="buttons-area-for-infos" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                        <button className="buttons-area-for-infos-btn" onClick={goToAnotherPage}>{t("csscanbackbutton")}</button>
                        <button className="buttons-area-for-infos-btn">{t("csscanappointmentbutton")}</button>
                    </div>

                </div>
                <Fot />
            </div>
        );
    }

    return <MyComponent />;
}
